import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews'
import { graphql, useStaticQuery } from 'gatsby'
import * as React from 'react'
import { PrismicRichText } from '@prismicio/react'

import { WithContext, WebSite } from 'schema-dts'
import DocumentHead from 'src/components/pageLayout/documentHead'

import CustomLink from 'src/utils/customLink'
import * as GlobalStyles from 'src/styles/global.module.scss'
import EmptyContainer from 'src/images/EmptyContainer.webp'
import Lid from 'src/images/canes-sauce-lid.webp'
import {
  NotFoundPageQuery,
  PrismicGlobalContentDataType,
} from 'src/typings/generated/graphql'

import * as Styles from './404.module.scss'

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query NotFoundPage {
      prismicGlobalContent {
        data {
          page_not_found_description {
            richText
          }
          page_not_found_link {
            ...BasicLinkFragment
          }
          page_not_found_link_text
          page_not_found_title
        }
      }
    }
  `) as NotFoundPageQuery

  const content = data.prismicGlobalContent
    ?.data as PrismicGlobalContentDataType

  return (
    <div>
      <div className={Styles.outerContainer}>
        <div className={Styles.sauceContainer}>
          <div className={Styles.lid}>
            <img src={Lid} alt="Lid for empty sauce container" />
          </div>
          <img src={EmptyContainer} alt="Empty Sauce Container" />
        </div>
        <h1 className={Styles.headline}>
          {content.page_not_found_title ?? ''}
        </h1>
        <div className={Styles.description}>
          <PrismicRichText
            field={content.page_not_found_description?.richText ?? ''}
            components={{
              hyperlink: ({ node, children }: any) =>
                CustomLink({ link: node.data, children }),
            }}
          />
        </div>
        <div className={Styles.button}>
          <CustomLink
            className={GlobalStyles.redGhostButton}
            link={content.page_not_found_link ?? ''}
          >
            {content.page_not_found_link_text ?? ''}
          </CustomLink>
        </div>
      </div>
    </div>
  )
}

export default withPrismicUnpublishedPreview(NotFoundPage)

export const Head = () => {
  const title = '404: Not Found'
  const url = '/404'

  const schema: WithContext<WebSite> = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url,
    name: title,
    headline: title,
  }

  return DocumentHead({
    title,
    url,
    description: '',
    imageUrl: '',
    pageSchema: schema,
  })
}
